.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#location_id_textfield {
    width: 512px;
    height: 88px;
    font-size: 3rem;
    text-align: center;
}

.Signin-button {
    margin: 16px;
    height: 44px;
    width: 250px;
}

.App-header {
  background-color: #5c5656;
  height: 64px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding-left: 8px;
  padding-right: 8px;
  color: white;
}

.Button-active {
    background-color: #5c5656;
    height: 50vh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    animation-name: Button-flash;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.Button-logout {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.Button-passive {
    background-color: #5c5656;
    height: 50vh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 48px;
}

li {
    list-style: none;
    text-align: left;
    padding: 16px;
    font-size: 24px;
}

li:hover {
    background-color: rgba(148, 148, 148, 0.2 );
}

li.new-item {
    font-weight: 600;
}

li.old-item {
    font-weight: lighter;
}
li > strong {
    color: #68A65D;
}

.No-orders {
    font-size: 48px;
    font-weight: 100;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Order-list {
    width: 80vw;
    height: 80vh;
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: space-between;
}

.logout {
    font-size: 14px;
    font-weight: 600;
    color: white;
    padding: 8px;
}

.logout:hover {
    background-color: white;
    color: red;
    cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Button-flash {
    from {
        background-color: greenyellow;
    }
    to {
        background-color: #68A65D;
    }
}